import { memo } from "react";
import PropTypes from "prop-types";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { WEBSITE_TUNNEL_LABELS, WEBSITE_TUNNEL_TYPES } from "app/constants";
import { FormattedMessage } from "react-intl";
import Typography, { TYPOGRAPHY_VARIANTS } from "app/pages/.shared/Typography/Typography";
import "./WebsiteTunnelSwitcher.scss";
import {
	AsyncHotelOnlySearchFormContainer,
	AsyncSmartDPSearchFormContainer,
	AsyncStopoverSearchFormContainer,
} from "app/routes";

const WebsiteTunnelSwitcher = ({ websiteTunnels = [] }) => {
	return (
		<div className="website-tunnel-switcher">
			<Tabs>
				<TabList>
					{websiteTunnels?.length > 1 &&
						websiteTunnels?.map((tunnel = {}, index) => {
							const websiteTunnelLabelId =
								WEBSITE_TUNNEL_LABELS[tunnel.websiteTunnelType];
							return (
								<Tab key={index}>
									<Typography variant={TYPOGRAPHY_VARIANTS.LARGE} isBold>
										<FormattedMessage id={websiteTunnelLabelId} />
									</Typography>
								</Tab>
							);
						})}
				</TabList>
				{websiteTunnels?.map((tunnel = {}, index) => (
					<TabPanel key={index}>
						{tunnel?.websiteTunnelType === WEBSITE_TUNNEL_TYPES.STOPOVER && (
							<AsyncStopoverSearchFormContainer disableFormPersistance />
						)}
						{tunnel?.websiteTunnelType === WEBSITE_TUNNEL_TYPES.HOLIDAYS && (
							<AsyncSmartDPSearchFormContainer disableFormPersistance />
						)}
						{tunnel?.websiteTunnelType === WEBSITE_TUNNEL_TYPES.HOTEL_ONLY && (
							<AsyncHotelOnlySearchFormContainer disableFormPersistance />
						)}
					</TabPanel>
				))}
			</Tabs>
		</div>
	);
};

WebsiteTunnelSwitcher.propTypes = {
	websiteTunnels: PropTypes.array,
};

export default memo(WebsiteTunnelSwitcher);
